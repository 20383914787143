import React from 'react';
import './App.css';

function App() {
  return (
    <div className="iframe-container">
      <iframe 
        src="https://tidaldao.super.site/" 
        title="tidalDAO"
        allowFullScreen
        frameBorder="0"
      />
    </div>
  );
}

export default App;
